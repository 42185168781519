import PropTypes from "prop-types";
import { Container, Navbar } from "react-bootstrap";
import { SiteHeaderNav } from "@/ui/SiteHeader/__components";
import clsx from "classnames";
import classes from "./styles.module.scss";
import { getRoute } from "@/functions/getRoute";
import { getGlobal } from "@/functions/getGlobal";
import { Image } from "@/ui";
import { useEffect, useState } from "react";
import { useRouter } from 'next/router';

export default function SiteHeader({ className }) {
  const global = getGlobal();
  const company_name = global["_theme.company.name"];
  const announcement = global["announcement.text"];
  const [scrollY, setScrollY] = useState(0);
  const router = useRouter();

  const isHomePage = router.asPath === '/';
  
  useEffect(() => {
    if (typeof document !== "undefined") {
      const headerElements = document.querySelectorAll(".nav-link");
      if (headerElements) {
        headerElements.forEach((element) => {
          element.style.color = (isHomePage && scrollY == 0) ? ( window.innerWidth < 991.98 ? "black" : "white" ) : "black";
        });

        if (!isHomePage) {
          const dropdownToggles = document.querySelectorAll('.dropdown-toggle');

          dropdownToggles.forEach(dropdownToggle => {
            const svgElement = dropdownToggle.querySelector('svg');
            svgElement.style.stroke = 'black';
          });
        }
      }
    }
  }, [isHomePage, scrollY]);


  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar fixed="top" collapseOnSelect expand="lg" className={clsx(className, classes.header, (!!announcement ? classes.announcement : ""), (scrollY > 0 ? classes.scrolled : ""))}>
      <Container className={`p-0 ${classes.cont}`}>
        <Navbar.Brand className={clsx(classes.header__brand, "me-auto")} href={getRoute("home")}>
          <Image img="/xcede_logo.webp" size="100x100" alt={company_name} />
        </Navbar.Brand>
        <Navbar.Toggle className="me-2" aria-controls="responsive-navbar-nav">
          <div>
            <span />
            <span />
            <span />
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav" className={clsx(classes.responsive_navbar_nav, "justify-content-sm-evenly")}>
          <SiteHeaderNav className={classes.navlink_cont} />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

SiteHeader.defaultProps = {
  className: "",
};

SiteHeader.propTypes = {
  className: PropTypes.string,
};
