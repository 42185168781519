import "@/scss/styles.scss";
import { getGlobal } from "@/functions/getGlobal";
import { Suspense } from "react";
import { SiteFooter, SiteHeader } from "@/ui";
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import SourceFlowTracker from "@sourceflow-uk/sourceflow-tracker";
import metaObject from "../../.sourceflow/metadata.json";
import Announcement from "@/ui/Announcement";
// import CookieConsent from '@/components/Shared/CookieConsent'
import Script from "next/script";

export default function App({ Component, pageProps }) {
  const global = getGlobal();
  const gtag = global["_theme.google.gtag"];

  return (
    <>
      <Suspense>
        <Announcement />
        <SourceFlowHead metaObject={metaObject}>
          {pageProps?.meta?.title && <title>{pageProps.meta.title}</title>}
        </SourceFlowHead>
        <SiteHeader className={global["_theme.header.classes"]} />
        <main className="flex-grow-1">
          <Component {...pageProps} />
        </main>
        <SiteFooter className={global["_theme.footer.classes"]} />
      </Suspense>
      {/* <CookieConsent> */}
      <Script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="df85e64a-fa72-4006-9b51-0820e44e5d19"
        data-blockingmode="auto"
      />

      {/* Bullhorn */}
      <Script
          id="BullHorn"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            window.hfAccountId = "d34d01ff-61a9-49d7-932d-bd234f0c6808";
            window.hfDomain = "https://api.herefish.com";
                (function() {
                    var hf = document.createElement('script'); hf.type = 'text/javascript'; hf.async = true;
                    hf.src = window.hfDomain + '/scripts/hf.js';
                    var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(hf, s);
                })();
              `,
          }}
        />


      {gtag && <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${gtag}`} />}
      {gtag && (
        <Script
          id="gtm_script"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gtag}');
              `,
          }}
        />
      )}
      <SourceFlowTracker cookieExpiryTime={3000} />
      {/* </CookieConsent> */}
      <Script
        id="jg-widget-xcede-group-1711620414407?utm_campaign=lc_frp_share_transaction_fundraiser_page_donation_received_-_nth_donation&utm_content=d70b7eaa-9a35-40d4-99b8-da4707b48013&utm_medium=email&utm_source=postoffice&utm_term=1715936499075-409"
        // id="charitybot"
        type="text/javascript"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
            (function(){var id="jg-widget-xcede-group-1711620414407?utm_campaign=lc_frp_share_transaction_fundraiser_page_donation_received_-_nth_donation&utm_content=d70b7eaa-9a35-40d4-99b8-da4707b48013&utm_medium=email&utm_source=postoffice&utm_term=1715936499075-409",doc=document,pfx=(window.location.toString().indexOf("https")==0)?"https":"http";var el=doc.getElementById(id);if(el){var js=doc.createElement('script');js.src=pfx+"://widgets.justgiving.com/fundraisingpage/xcede-group-1711620414407?utm_campaign=lc_frp_share_transaction_fundraiser_page_donation_received_-_nth_donation&utm_content=d70b7eaa-9a35-40d4-99b8-da4707b48013&utm_medium=email&utm_source=postoffice&utm_term=1715936499075?enc=ZT1qZy13aWRnZXQteGNlZGUtZ3JvdXAtMTcxMTYyMDQxNDQwNz91dG1fY2FtcGFpZ249bGNfZnJwX3NoYXJlX3RyYW5zYWN0aW9uX2Z1bmRyYWlzZXJfcGFnZV9kb25hdGlvbl9yZWNlaXZlZF8tX250aF9kb25hdGlvbiZ1dG1fY29udGVudD1kNzBiN2VhYS05YTM1LTQwZDQtOTliOC1kYTQ3MDdiNDgwMTMmdXRtX21lZGl1bT1lbWFpbCZ1dG1fc291cmNlPXBvc3RvZmZpY2UmdXRtX3Rlcm09MTcxNTkzNjQ5OTA3NS00MDkmdz00MDAmYj1pbWFnZSxkb25hdGUmaWI9dGl0bGUscHJvZ3Jlc3MscmFpc2VkLHRhcmdldA%3D%3D";el.parentNode.insertBefore(js, el);}})();
            `,
        }}
      />
    </>
  );
}
